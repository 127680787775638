/* eslint-disable */

import { I18n } from '@lyfta/components-i18n'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { CSSTransition } from 'react-transition-group'

import { LyftaForm } from '../../Blocks/Form'
import { Box } from '../Box'
import { Flex } from '../Flex'
import { ModalButtons } from './ModalButtons'
import {
  ButtonsContainer,
  Close,
  Container,
  ContentWrapper,
  Description,
  Footer,
  Header,
  IconModal,
  Subtitle,
  Title,
  Wrapper,
} from './styles'

// MODAL PROPS
// hideClose
// isOpen
// showDefaultButtons
// showDefaultHeader
// renderCustomHeader
// renderCustomContent
// renderCustomButtons
// onCallback
// onDismissModal
// iconChatBubble
// form = fields, formStyle, validate
// modalStyle =
//     titleTag, subtitleTag, descriptionTag, buttonsTag
//     titleContainer, subtitleContainer, descriptionContainer, buttonsContainer, buttonContainer, headerContainer, contentContainer, footerContainer,formContainer,
// content = title, subtitle, description, modelName, count, sentenceCase, otherOptions
// buttons = cancelText, okText, okDisabled, deleteOrRemove
// children

const Modal = ({
  animation,

  children,
  hideClose,
  isOpen,

  showFooter,
  onCallback,
  onDismissModal,
  minWidth,
  minHeight,
  maxWidth,
  removeBackground,

  // showDefaultHeader, TODO?
  // renderCustomHeader, TODO?
  renderCustomContent,
  renderCustomButtons,

  iconChatBubble,

  form,
  modalStyle,
  content,
  buttons,
  shouldReturnFocus,
}) => {
  /* eslint-disable */

  const {
    titleTag: TitleTag,
    subtitleTag: SubtitleTag,
    descriptionTag: DescriptionTag,
    buttonsTag: ButtonsTag,
    headerContainer,
    wrapperContainer,
    contentContainer,
    footerContainer,
    formContainer,
    titleContainer,
    subtitleContainer,
    descriptionContainer,
    buttonsContainer, // Button wrapper
    buttonContainer, // Button input
    ...modalContainerStyles
  } = modalStyle ?? {}

  const {
    title,
    subtitle,
    description,
    modelName,
    count,
    sentenceCase,
    otherOptions,
  } = content ?? {}

  const { fields, formStyle, validate, showFormButtons, ...restFormProps } =
    form ?? {}

  const { cancelText, okText, okDisabled, deleteOrRemove, showButtons } =
    buttons ?? {}

  const showButtonsDefault = showButtons ?? true

  const hasIcon = !!iconChatBubble

  const modalOkText =
    (typeof okText === 'string' && <I18n text={okText} />) ||
    okText ||
    undefined

  const modalCancelText =
    (typeof cancelText === 'string' && <I18n text={cancelText} />) ||
    cancelText ||
    undefined

  const modalTitle =
    (typeof title === 'string' && (
      <I18n
        i18nKey={title}
        options={{ modelName, count }}
        sentenceCase={sentenceCase}
        tag={TitleTag || Title}
        {...titleContainer}
      />
    )) ||
    title ||
    undefined

  const modalSubtitle =
    (typeof subtitle === 'string' && (
      <I18n
        i18nKey={subtitle}
        options={{ modelName, count }}
        sentenceCase={sentenceCase}
        tag={SubtitleTag || Subtitle}
        {...subtitleContainer}
      />
    )) || <Flex {...subtitleContainer}>{subtitle}</Flex> ||
    undefined

  /* eslint-disable */

  const modalDescription = Array.isArray(description) ? (
    description.map((desc, index) =>
      typeof desc === 'string' ? (
        <Box mb={2}>
          <I18n
            key={index} // Agregar un key único para cada elemento del array
            i18nKey={desc}
            options={{ modelName, count, ...otherOptions }}
            sentenceCase={sentenceCase}
            tag={DescriptionTag || Description}
            {...descriptionContainer}
          />
        </Box>
      ) : (
        <Box mb={2}>{desc}</Box> || undefined
      ),
    )
  ) : typeof description === 'string' && !description.includes(':') ? (
    <I18n
      i18nKey={description}
      options={{ modelName, count, ...otherOptions }}
      sentenceCase={sentenceCase}
      tag={DescriptionTag || Description}
      {...descriptionContainer}
    />
  ) : (
    description || undefined
  )

  const handleCancel = useCallback(
    e => {
      if (e) e.stopPropagation()
      if (onDismissModal) return onDismissModal()
      if (onCallback) return onCallback(false)
      return null
    },
    [onCallback, onDismissModal],
  )

  const handleOk = useCallback(
    e => {
      if (e) e.stopPropagation()
      if (onCallback) return onCallback(true)
      return null
    },
    [onCallback],
  )

  const formProps = {
    cancelText: modalCancelText,
    handleCancel,
    okDisabled,
    okText: modalOkText,
    showButtons: showFormButtons,
  }

  if (removeBackground)
    return (
      <Container
        appElement={document.getElementById('root')}
        closeTimeoutMS={170}
        isOpen={isOpen}
        onRequestClose={handleCancel}
      >
        {children}
      </Container>
    )

  return (
    <CSSTransition in={isOpen} timeout={300} classNames="modal" unmountOnExit>
      <Container
        {...modalContainerStyles}
        appElement={document.getElementById('root')}
        closeAnimation={animation?.closeAnimation}
        closeTimeoutMS={animation?.closeAnimation?.transitionDuration || 170}
        endAnimation={animation?.endAnimation}
        fitContent
        hasIcon={hasIcon}
        isOpen={isOpen}
        minHeight={minHeight}
        minWidth={minWidth}
        maxWidth={maxWidth || modalContainerStyles?.maxWidth}
        onRequestClose={handleCancel}
        shouldReturnFocusAfterClose={shouldReturnFocus ?? true}
        startAnimation={animation?.startAnimation}
        id="modal-container"
      >
        {!hideClose && <Close id="close-modal-btn" onClick={handleCancel} />}
        <IconModal>{iconChatBubble && iconChatBubble}</IconModal>

        <Wrapper hasIcon={hasIcon} id="wrapper" {...wrapperContainer}>
          {(modalTitle || modalSubtitle) && (
            <Header {...headerContainer}>
              {modalTitle && modalTitle}
              {modalSubtitle && modalSubtitle}
            </Header>
          )}

          <ContentWrapper id="content-wrapper" {...contentContainer}>
            {renderCustomContent && renderCustomContent()}

            {modalDescription && !renderCustomContent && (
              <Description {...descriptionContainer}>
                {modalDescription}
              </Description>
            )}

            {fields && (
              <LyftaForm
                disableModalFormTitle // looks like that all ModalFormNew has this props as true.
                fields={fields}
                modalProps={formProps}
                noWrap
                submitMethod={onCallback}
                validate={validate}
                withoutHeader
                wrapperProps={{ margin: '0', ...formStyle, ...formContainer }}
                {...restFormProps}
              />
            )}

            {children && children}
          </ContentWrapper>
          {renderCustomButtons && renderCustomButtons()}

          {showFooter && !renderCustomButtons && (
            <Footer id="footer" {...footerContainer}>
              <ButtonsContainer {...buttonsContainer} id="modal-buttons">
                <ModalButtons
                  showButtons={showButtonsDefault}
                  cancelText={cancelText}
                  deleteOrRemove={deleteOrRemove}
                  handleCancel={handleCancel}
                  handleOk={handleOk}
                  buttonContainer={buttonContainer}
                  okText={okText}
                />
              </ButtonsContainer>
            </Footer>
          )}
        </Wrapper>
      </Container>
    </CSSTransition>
  )
}

Modal.defaultProps = {
  cancelText: <I18n i18nKey="actions.cancel" />,
  children: null,
  count: '',
  fitContent: false,
  hideClose: false,
  i18nText: '',
  isLoading: false,
  isModalForm: false,
  isOpen: false,
  minWidth: null,
  minHeight: null,
  modalText: {},
  removeBackground: false,
  showFooter: true,
  text: null,
  title: null,
  okText: <I18n i18nKey="actions.ok" />,
  onCallback: null,
  onDismissModal: null,
}

Modal.propTypes = {
  cancelText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  children: PropTypes.node,

  fitContent: PropTypes.bool,
  hideClose: PropTypes.bool,
  i18nText: PropTypes.string,
  isLoading: PropTypes.bool,
  isModalForm: PropTypes.bool,
  isOpen: PropTypes.bool,

  okText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  removeBackground: PropTypes.bool,
  showFooter: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onCallback: PropTypes.func,
  onDismissModal: PropTypes.func,
}

Modal.Close = Close

export default Modal

// EXAMPLE OF MODAL

//  <Modal
//   hideClose
//   isOpen={!currentActivity.shown}
//   // Make the parts of a modal more flexible.
//   renderCustomHeader={renderCustomHeader}
//   renderCustomContent={renderCustomContent} // Forms can be passed here too (alternative to form prop)
//   renderCustomButtons={renderCustomButtons}
//   // By default, the modal has 2 options, OK (onCallback) and cancel (onDismissModal). NOTE: onAlternativeFunction seems not to be necessary. onCallback can be used for both by passing the parameter false.
//   onCallback={() => onChoosePositive()}
//   onDismissModal={() => onChooseNegative()}

//   // Icon having the modal as chat bubble?
//   iconChatBubble={<Icon mr={2} glyph={Film} height={height} width={width} />}

//   // FORM
//   form={{
//     fields: classDropdown || fields, // Replacing nonModalFormFields
//     // formStyle: { // modalProps in LyftaForm component
//     formStyle: {
//       width: "100%",
//       justifyContent: "center",
//     },
//   }}

//   // STYLES
//   modalStyle={{
//     // Styles of the modal (wrapper)
//     width: "500px",
//     borderRadius: "30px",
//     fixHeader: true, // Header always visible
//     fixFooter: true, // Buttons always visible

//     // Tags availables: TitleModal, SubtitleModal, DescriptionModal, ButtonsModal
//     titleTag: TitleModal,
//     subtitleTag: SubtitleModal,
//     descriptionTag: DescriptionModal,
//     buttonsTag: ButtonsModal,

//     // Styles of the tags (TitleModal, SubtitleModal, DescriptionModal, ButtonsModal)
//     titleContainer: {
//       justifyContent: "center",
//     },
//     subtitleContainer: {
//       justifyContent: "center",
//     },
//     descriptionContainer: {
//       justifyContent: "center",
//     },
//     buttonsContainer: {
//       // Button wrapper
//       justifyContent: "center",
//     },
//     buttonContainer: {
//       //Button input
//       justifyContent: "center",
//     },
//     // Styles of the of the modal parts (header, content, footer) and form
//     headerContainer: {
//       justifyContent: "center",
//     },
//     contentContainer: {
//       justifyContent: "center",
//     },
//     footerContainer: {
//       justifyContent: "center",
//     },
//     formContainer: {
//       justifyContent: "center",
//       disableModalFormTitle: true, // Disable the title of the form
//     },
//   }}
//   // CONTENT
//   // Can be a string or a component (classDropdown for description)
//   content={{
//     title: { title },
//     subtitle: { subtitle },
//     description: { description },
//     modelName,
//     count,
//     sentenceCase,
//     otherOptions,
//   }}
//   // BUTTONS
//   // Can be a string or a component (classDropdown for description)
//   buttons={{
//     cancelText: { cancelText },
//     okText: { okText },
//     okDisabled: (invalid, pristine) => pristine || invalid,
//     showButtons: false,
//   }}
// >
//   // {children}
// // </Modal>
