/* eslint-disable */
import { styled, themeGet } from '@lyfta/components-theme'
import colors from '@lyfta/components-theme/src/themes/Main/colors'
import shadows from '@lyfta/components-theme/src/themes/Main/shadows'
import { Flex, Icon, Kebab, ReactSelectDropdown } from '@lyfta/components-ui'
import { components } from 'react-select'

export const Card = styled(Flex).attrs(() => ({ mx: 4, my: 2 }))`
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: ${themeGet('colors.neutral_100')};
  width: 95%;
  height: 299px;
  flex-shrink: 0;
  border-radius: 10px;
  box-shadow: ${shadows.lifted};
  cursor: pointer;
  flex: 1;
  &:hover {
    box-shadow: ${shadows.raised};
  }
`

export const LeftContainer = styled(Flex)`
  width: 408px;
  height: 300px;
  flex-shrink: 0;
  border-radius: 10px 0px 0px 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: ${({ image }) =>
    `url('${
      image ||
      'https://assets-lyfta.imgix.net/campaign/image/salallll-sm-dd526e7487026f3569d3-10db2c3bfec60ed47ed6.jpg'
    }')`};
`

export const RightContainer = styled(Flex)`
  margin-left: 16px;
  flex-direction: column;
  justify-content: space-evenly;
`
export const CardTitle = styled(Flex)`
  color: var(--teacher-app-black-black-800, #050b35);
  justify-content: flex-start;
  align-items: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.5px;
`

export const CardDescription = styled(Flex)`
  font-size: 19px;
  font-style: normal;
  margin-right: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.5px;
  color: ${themeGet('colors.black_800')};
`

export const CardAuthor = styled(Flex)`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: ${themeGet('colors.black_600')};
`

export const CardAuthorIcon = styled(Flex)`
  width: 60px;
  height: 60px;
  background-color: ${p =>
    p.initials ? 'white' : themeGet('colors.primary_100')};
  border: ${p =>
    p.initials ? `4px solid ${themeGet('colors.primary_100')(p)}` : 'none'};

  color: ${p => (p.initials ? themeGet('colors.primary_100') : 'black')};
  font-weight: 800;
  font-size: 24px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  letter-spacing: 1.5px;
`

export const CardFooter = styled(Flex)`
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: ${themeGet('colors.secondary_130')};
`
export const ViewersContainer = styled(Flex)`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: ${themeGet('colors.secondary_60')};
`
export const ViewContainer = styled(Flex)`
  position: absolute;
  bottom: 20px;
  right: 5px;
`
export const KebabContainer = styled(Flex)`
  position: absolute;
  top: 24px;
  right: 20px;
`

const KebabButtonCustomControl = props => {
  const { children } = props
  return (
    <components.Control {...props}>
      <Icon fill="black_900" glyph={Kebab} height={20} width={20} />
      {children}
    </components.Control>
  )
}

const kebabButtonExtendStyles = props => {
  return {
    control: defaultStyles => ({
      ...defaultStyles,
      height: '44px',
      width: '44px',
      '&:hover svg path': {
        fill: colors.secondary,
      },
    }),
    menu: defaultStyles => ({
      ...defaultStyles,
      borderRadius: '4px',
      left: '-280px',
      bottom: '-60px',
    }),
    option: defaultStyles => ({
      ...defaultStyles,
      padding: '10px 20px 10px 20px',
      backgroundColor: themeGet('colors.neutral_100')(props),
      '&:hover': {
        backgroundColor: themeGet('colors.primary_60_50')(props),
        cursor: 'pointer',
      },
    }),
    valueContainer: defaultStyles => ({
      ...defaultStyles,
      borderRadius: '50%',
      height: '44px',
      position: 'absolute',
      width: '44px',
    }),
  }
}
const KebabButtonCustomOption = props => {
  // eslint-disable-next-line
  const { children } = props

  return <components.Option {...props}>{children}</components.Option>
}

export const KebabButton = styled(ReactSelectDropdown).attrs(props => ({
  extendComponents: {
    Control: KebabButtonCustomControl,
    DropdownIndicator: () => null,
    IndicatorsContainer: () => null,
    Option: KebabButtonCustomOption,
    SingleValue: () => null,
  },
  extendStyles: kebabButtonExtendStyles(props),
}))``
